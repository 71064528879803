import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './reservationCalendar.scss';
import Spinenr from './Spinner';

const toLocaleDate = (isoString) => moment(isoString).format('DD.MM.YYYY');

const nextMonth = (dates) => ({
    startDate: moment(dates.startDate).add(1, 'month').startOf('month').toISOString(),
    endDate: moment(dates.endDate).add(1, 'month').endOf('month').toISOString(),
});

const previousMonth = (dates) => ({
    startDate: moment(dates.startDate).subtract(1, 'month').startOf('month').toISOString(),
    endDate: moment(dates.endDate).subtract(1, 'month').endOf('month').toISOString(),
});

const ReservationCalendar = () => {
    const [dates, setDates] = useState({
        startDate: moment().startOf('month').toISOString(),
        endDate: moment().endOf('month').toISOString(),
    });
    const [events, setEvents] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setLoading(true);
                const resp = await fetch(`/api/calendar?startDate=${dates.startDate}&endDate=${dates.endDate}`);
                const json = await resp.json();
                setEvents(json.events);
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        }
        fetchEvents();
    }, [dates])
    return (
        <div>
            <h3 style={{ margin: 0, }}>Varatut ajankohdat {toLocaleDate(dates.startDate)} - {toLocaleDate(dates.endDate)}</h3>
            <div className="navigation-buttons">
                <button
                    onClick={() => setDates(previousMonth(dates))}
                    className="button-clear button-small"
                >
                    Edellinen kuukausi
                </button>
                <button
                    onClick={() => setDates(nextMonth(dates))}
                    className="button-clear button-small"
                >
                    Seuraava kuukausi
                </button>
            </div>
            {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                    <Spinenr />
                </div>
            ) : (
                events.length > 0 ? (
                    <ul>
                        {events.map((event, index) => (
                            <li key={index}>
                                {toLocaleDate(event.start)} - {toLocaleDate(event.end)}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div style={{ textAlign: 'center', paddingBottom: '1em', }}>
                        Ei varauksia
                    </div>
                )
            )}
            
        </div>
    )
};

export default ReservationCalendar;
