import React from 'react';
import { Element } from 'react-scroll';
import './main.scss';
import ContactForm from "./ContactForm";
import ReservationCalendar from "./ReservationCalendar";
import { MdIcon } from "./MdIcon";

const Main = () => (
    <main id="main" className="container">
        <Element name="main">
            <h2>
                Miten paljun vuokraus toimii?
            </h2>
            <p>
                Paljun vuokraus on helppoa. Ota meihin yhteyttä ja tule noutamaan paljusi vaikka heti! Paljua voi vetää
                tavallisella henkilöautolla ja B-ajokortilla. Tyhjänä paljukärry on niin kevyt, että se on helppo siirtää
                paikalleen lihasvoimin. Voimme vaihtoehtoisesti toimittaa paljun teille paikan päälle lisämaksusta.
                Paljumme sijaitsee Taipalsaarella, n. 10 km Lappeenrannan keskustasta.
            </p>
            <p>
                Tilavuudeltaan 1200 litrainen palju lämpiää kätevästi puulämmitteisellä kamiinalla. Lämmitysaika on
                noin 2-3 tuntia. Kylpijöitä paljuun mahtuu kerralla jopa 8 henkeä. Tarvittaessa voimme toimittaa myös
                polttopuut.
            </p>
            <p>
                <strong>
                    Yrittäjänä tiedostamme koronaviruspandemian aiheuttamat riskit ja otamme ne vakavasti. Paljumme
                    vuokrataan aina huolellisesti pestynä ja desinfioituna. Asiakkaana teidän ei tarvitse pestä paljua käytön
                    jälkeen.
                </strong>
            </p>
            <h2>
                Miten varataan?
            </h2>
            <p>
                Voit soittaa meille, laittaa tekstiviestiä tai ottaa yhteyttä sivuston alalaidan varauspyyntö-lomakkeella.
                Tarkista varatut ajankohdat oheisesta varauskalenteristamme.
            </p>
            <h2>
                Hinnasto <MdIcon icon="euro_symbol" />
            </h2>
            <p>Alla voimassaoleva hinnastomme. Pidemmät vuokra-ajat hinnoitellaan erikseen, kysy rohkeasti tarjousta.</p>
            <ul>
                <li>
                    Ma-To 50 €/vrk
                </li>
                <li>
                    Pe-Su 90 €/vrk
                </li>
                <li>
                    Koko viikonloppu (pe-su) 120 €
                </li>
                <li>
                    Juhlapyhät hinnoitellaan erikseen
                </li>
            </ul>
            <hr />
            <h2>
                Yhteystiedot <MdIcon icon="contact_support" />
            </h2>
            <ul className="o-list-bare">
                <li><MdIcon icon="email" style={{ verticalAlign: 'bottom' }} /> Sähköposti: <a href="mailto:info@lappeenpalju.fi">info@lappeenpalju.fi</a></li>
                <li><MdIcon icon="phone" style={{ verticalAlign: 'bottom' }} />Puhelinnumero: <a href="tel:+358504630926">+358 50 4630926</a></li>
            </ul>
        </Element>
        <hr />
        <ReservationCalendar />
        <hr />
        <ContactForm />
    </main>
);

export default Main;
