import React from 'react';
import { useFormikContext, getIn } from "formik";

const FormikError = ({ name }) => {
    const { errors } = useFormikContext();
    const errorMsg = getIn(errors, name);
    if (errorMsg) {
        return (
            <span className="u-color-negative">
                {errorMsg}
            </span>
        );
    }
    return null;
}

export default FormikError;
