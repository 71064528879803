import React, { Fragment } from 'react';
import { Link } from 'react-scroll';
import { MdIcon } from "./MdIcon";

const Header = () => {
    return (
        <Fragment>
            <nav>
                <h2>Lappeenpalju.fi</h2>
            </nav>
            <header>
                <h1>Paljukärryn vuokraus</h1>
                <p>Synttärit, polttarit, pikkujoulut, juhannus tai vappu – palju kruunaa juhlan kuin juhlan! Vuokraamme siirrettäviä kylpytynnyreitä eli mobiilipaljuja Lappeenrannan alueella sekä pääkaupunkiseudulla.</p>
                <Link style={{ cursor: 'pointer', }} to="contactForm" smooth className="button">
                    Vuokraa nyt <MdIcon style={{ verticalAlign: 'text-bottom' }} icon="hot_tub" />
                </Link>
                <Link smooth to="main" className="down" >&#8595;</Link>
            </header>
        </Fragment>
    )
};

export default Header;
