import React from 'react';
import './feedback.scss';

const Feedback = ({ children, isError }) => (
    <div className={`c-feedback ${isError ? 'is-error' : 'is-success'}`}>
        {children}
   </div>
);

export default Feedback;
