import React, { useState, Fragment } from 'react';
import { Element } from 'react-scroll';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import FormikError from "./FormikError";
import Feedback from "./Feedback";

const postContact = (model) =>
    fetch(
        '/api/contact',
        {
            method: 'POST',
            body: JSON.stringify(model),
            headers: { 'Content-Type': 'application/json' },
        },
    );

const ContactForm = () => {
    const [isSending, setSending] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(false);
    return (
        <div style={{ marginTop: '4vh' }}>
            <Element name="contactForm">
                <Formik
                    initialValues={{
                        name: '',
                        tel: '',
                        email: '',
                        date: '',
                        comment: '',
                        firewood: false,
                        delivery: false,
                        postCode: '',
                    }}
                    onSubmit={(model, formikHelpers) => {
                        setSending(true);
                        postContact(model).then((resp) => {
                            if (resp.status === 200) {
                                setSending(false);
                                setSuccess(true);
                                setTimeout(() => setSuccess(false), 10000);
                                formikHelpers.resetForm();
                            } else {
                                setSending(false);
                                setError(true);
                            }
                        })
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Kenttä on pakollinen'),
                        tel: Yup.string().required('Kenttä on pakollinen'),
                        date: Yup.string().required('Kenttä on pakollinen'),
                    })}
                >
                    {({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <h2>Varauspyyntö</h2>
                                <p>Vastaamme jokaiseen pyyntöön sähköpostitse/puhelimitse.</p>
                                <label htmlFor="name">Nimi</label>
                                <Field name="name" type="text" id="name" placeholder="Erkki Esimerkki" />
                                <FormikError name="name" />
                                <label htmlFor="tel">Puhelinnumero</label>
                                <Field name="tel" type="tel" id="tel" placeholder="+358 123 456 789" />
                                <FormikError name="tel" />
                                <label htmlFor="email">Sähköposti</label>
                                <Field name="email" type="email" id="email" placeholder="erkki.esimerkki@example.com" />
                                <FormikError name="email" />
                                <label htmlFor="date">Vuokrausajankohta</label>
                                <Field name="date" type="text" id="date" placeholder="Esim 15.5-17.5" />
                                <FormikError name="date" />
                                <label htmlFor="commentField">Lisätietoa, toiveita</label>
                                <Field component="textarea" name="comment" id="commentField" />
                                <h3>Lisäpalvelut</h3>
                                <div>
                                    <Field id="firewood" type="checkbox" name="firewood" />
                                    <label className="label-inline" htmlFor="firewood">Polttopuut (20€ / lämmityskerta)</label>
                                </div>
                                <div>
                                    <Field id="delivery" type="checkbox" name="delivery" />
                                    <label className="label-inline" htmlFor="delivery">Kuljetus (Alkaen 10€)</label>
                                </div>
                                {values.delivery && (
                                    <Fragment>
                                        <label htmlFor="postCode">Postinumero</label>
                                        <Field type="tel" name="postCode" id="postCode" placeholder="Kuljetusmaksun arviointia varten" />
                                    </Fragment>
                                )}
                                <input disabled={isSending} className="button-primary button-block" type="submit" value="Lähetä" />
                                {isError && (
                                    <Feedback isError>
                                        Valitettavasti viestin lähetys ei onnistunut. Voit olla myös yhteydessä <a href="mailto:info@lappeenpalju.fi">info@lappeenpalju.fi</a> suoraan.
                                    </Feedback>
                                )}
                                {isSuccess && (
                                    <Feedback>
                                        Varauspyyntö lähettiin. Olemme yhteydessä Sinuun mahdollisimman pian.
                                    </Feedback>
                                )}
                            </fieldset>
                        </form>
                    )}
                </Formik>
            </Element>
        </div>
    );
};

export default ContactForm;
