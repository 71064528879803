import React, { Fragment } from 'react';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

const NewApp = () => (
    <Fragment>
        <Header />
        <Main />
        <Footer />
    </Fragment>
);

export default NewApp;